
.product-weekly-report {

  .alert {
    padding: 0.43rem 1.25rem;
  }

  .custom-table-wrapper {
    height: unset;

    table {
      height: fit-content;

      thead {
        position: sticky;
        top: 0;
        z-index: 1;

        th {
          white-space: nowrap;
          vertical-align: middle;
          width: 100%;
          &:first-child {
            position: sticky;
            left: 0;
            background-color: var(--table-tr-bg);
            text-align: left !important;
          }
          &:not(:nth-child(2)) {
            text-align: right;
          }
          &.product-img {
            width: 160px;
          }
        }
      }

      td {
        &:not(:nth-child(2)) {
          text-align: right;
        }
        &.total {
          max-width: 400px;
          white-space: break-spaces;
        }
        &:first-child {
          position: sticky;
          left: 0;
          background-color: var(--table-tr-bg);
        }
        &.product-name {
          white-space: break-spaces;
        }
      }
    }
  }
}
