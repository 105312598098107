
.stats {
  display: flex;
  justify-content: center;
  align-items: center;

  &__text {
    text-align: end;
    width: 50%;
  }

  &__value {
    text-align: start;
    margin-left: 0.375rem;
    font-size: 10px;
    width: 40px;
  }
}
